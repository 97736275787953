import React, { useEffect, useState } from 'react';
import { ImagineLearning, TwigScience } from '@twigeducation/ts-fe-components/components/Logos';
import {
    ContentArea,
    Footer,
    FooterText,
    HeaderContainer,
    LogoContainer,
    PageContainer,
    Sidebar,
    StyledArrow,
    StyledAside,
    StyledButton,
    StyledHeader,
    StyledPill,
    StyledUL,
    TroubleShooterContainer,
    Wrapper,
} from './Troubleshooter.styled';
import Panel from './Panel';
import LoadingSection from './Loading/LoadingSection';
import ChecksSuccessful from './Successful/ChecksSuccessful';
import FailurePage from './Failure/FailurePage';
import browserMainCheck from './Checks/browserMainCheck';
import ResolutionChecker from './Checks/ResolutionChecker';
import WhitelistChecker from './Checks/WhitelistChecker';
import { Translation } from '../../components/Translation';

const TroubleShooter = () => {
    const HeaderImageSize = 163.923;
    const [loading, setLoading] = useState(true);
    const checks = [browserMainCheck, ResolutionChecker, WhitelistChecker];
    const [content, setContent] = useState(LoadingSection());

    useEffect(() => {
        let mounted = true;
        const troubleshoot = async () => {
            if (mounted) {
                const results = await Promise.all(checks.map(fn => fn()));
                const errorMessages = results.filter(result => result.errorStatus).map(result => result.message);
                if (errorMessages.length > 0) {
                    setContent(FailurePage(errorMessages));
                } else {
                    setContent(ChecksSuccessful());
                }
                setLoading(false);
            }
        };
        troubleshoot();
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <PageContainer>
            <StyledHeader>
                <TwigScience width={HeaderImageSize} />
            </StyledHeader>
            <Wrapper>
                <HeaderContainer>
                    <h3>
                        <Translation translationKey="troubleshooter.troubleshooter" defaultValue="Troubleshooter" />
                    </h3>
                    <StyledPill />
                </HeaderContainer>
                <TroubleShooterContainer>
                    <ContentArea>
                        <div>{loading ? LoadingSection() : content}</div>
                    </ContentArea>
                    <Sidebar>
                        <Panel
                            title={
                                <Translation
                                    translationKey="troubleshooter.dashboard.title"
                                    defaultValue="Helpful Links"
                                />
                            }
                        >
                            <StyledUL>
                                <li>
                                    <a
                                        href="https://help.twigscience.com/hc/en-us/articles/14682502726423-System-Requirements"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Translation
                                            translationKey="troubleshooter.dashboard.system_requirements"
                                            defaultValue="System Requirements"
                                        />
                                        <StyledArrow size={10} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://help.twigscience.com/hc/en-us/articles/14682795948183-Supported-Integrations-for-Elementary"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Translation
                                            translationKey="troubleshooter.dashboard.integrations_elementary"
                                            defaultValue="Supported Integrations for Elementary"
                                        />
                                        <StyledArrow size={10} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://help.twigscience.com/hc/en-us/articles/14682408079511-Supported-Integrations-for-Middle-School"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Translation
                                            translationKey="troubleshooter.dashboard.integrations_middle"
                                            defaultValue="Supported Integrations for Middle School"
                                        />
                                        <StyledArrow size={10} />
                                    </a>
                                </li>
                            </StyledUL>
                        </Panel>
                        <StyledAside>
                            <h5>
                                <Translation translationKey="troubleshooter.aside_header" defaultValue="Need help?" />
                            </h5>
                            <p>
                                <Translation
                                    translationKey="troubleshooter.aside_content"
                                    defaultValue={
                                        "We’re just a click away! Contact us anytime you'd like a helping hand."
                                    }
                                />
                            </p>
                            <StyledButton
                                href="https://help.twigscience.com/hc/en-us/requests/new"
                                link
                                target="_blank"
                            >
                                <Translation
                                    translationKey="troubleshooter.contact_support"
                                    defaultValue="Contact Support"
                                />
                            </StyledButton>
                        </StyledAside>
                    </Sidebar>
                </TroubleShooterContainer>
            </Wrapper>
            <Footer>
                <LogoContainer>
                    <ImagineLearning primaryColor="#ffffff" />
                </LogoContainer>
                <FooterText>
                    <a href="https://twigeducation.com/privacy" target="_blank" rel="noreferrer">
                        <Translation translationKey="troubleshooter.footer.privacy" defaultValue="Privacy Policy" />
                    </a>
                    |
                    <a href="https://app.twigscience.com/terms-of-use" target="_blank" rel="noreferrer">
                        <Translation
                            translationKey="troubleshooter.footer.terms_and_conditions"
                            defaultValue="Terms and Conditions"
                        />
                    </a>
                    |
                    <span>
                        <Translation
                            defaultValue="Copyright © 2024, All rights reserved "
                            translationKey="troubleshooter.footer.rights_reserved"
                        />
                    </span>
                </FooterText>
            </Footer>
        </PageContainer>
    );
};

export default TroubleShooter;
