import { Info } from '@twigeducation/ts-fe-components/components/Icons';
import styled from 'styled-components';

export const StyledInfo = styled(Info)`
    margin-right: 10px;
`;

export const FailurePageHeader = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;

    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fb2162;
    }
`;

export const FailurePageContainer = styled.div`
    a {
        text-decoration: none;
    }
`;

export const FailureSection = styled.div`
    margin-top: 29px;
    h4 {
        color: black;
    }
    ul {
        margin-top: 19px;
        overflow-wrap: break-word;
    }
`;
