import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100%;
    padding: 1rem;
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        padding: 2.5rem 0 0 0;
    }
`;

const StyledContents = styled.div`
    margin: auto;
    width: 100%;
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        width: 60%;
    }
`;

const DropdownsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    gap: 24px;
    & > div {
        width: 100%;
    }
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        gap: 18px;
        flex-direction: row;
        & > div {
            width: 50%;
        }
    }
`;

const StyledContentBackground = styled.div`
    background: #fefefe;
`;

const StyledContentExplorer = styled.div`
    width: 100%;
    margin: auto;
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        width: 75%;
    }
`;

export { StyledContainer, StyledContents, DropdownsContainer, StyledContentExplorer, StyledContentBackground };
