import styled from 'styled-components';

export const Heading = styled.h2`
    font-size: 1.2rem;
    background: #f8f8f8;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #efefef;
    border-radius: 0.5rem 0.5rem 0 0;
    margin: 0;
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        padding: 1rem;
    }
`;

export const PanelContainer = styled.section`
    margin-bottom: 2rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: -0.05rem 0.05rem 0.5rem 0.16667rem #ccc;
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        padding: 0;
    }
`;

export const PanelWrapper = styled.div`
    background: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 1rem;
`;

export const Subtitle = styled.span`
    ${props => props.theme.primaryFont};
`;
