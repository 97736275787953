import styled from 'styled-components';

const StyledQuestion = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    padding: 0;
    row-gap: 20px;
    font-size: 16px;
    margin-top: 15px;
    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        grid-template-columns: 20% 80%;
        row-gap: 50px;
        padding: 5px;
    }
`;

const DrivingQuestion = styled.div`
    font-size: ${props => (props.descHeading ? '14px' : '16px')};
    ${props => (props.drivingQuestion ? 'color:#343a72; font-weight:bold' : 'color: #ff126d')};
`;

export { StyledQuestion, DrivingQuestion };
