import styled, { css } from 'styled-components';
import { Tab } from 'react-tabs';
import { Panel } from 'rc-collapse';

const focusStyles = css`
    outline: 2px transparent solid;
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px ${({ theme }) => theme.twigPinkColor}, 0 0 0px 4px #ffffff;
`;

export const StyledTab = styled(Tab)`
    font-family: ${({ theme }) => theme.primaryFont};
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.primaryColor};
    padding: 0 1.5rem 0.6rem;
    list-style: none;
    display: inline-block;
    margin-left: -1.25rem;
    margin-right: 1.25rem;
    margin-top: 2rem;
    font-size: 16px;
    &[aria-selected='true'] {
        border-bottom: 5px solid ${({ theme }) => theme.primaryColor};
        font-family: ${({ theme }) => theme.primaryFont};
        font-weight: 700;
    }
    &[aria-selected='false'] {
        padding-bottom: 0.9rem;
    }
    &:hover {
        cursor: pointer;
    }
    &:focus-visible {
        outline: 2px ${({ theme }) => theme.primaryColor} solid;
        border-radius: 2px;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 2rem;

    a:focus-visible {
        ${focusStyles}
    }
    @media screen and (max-width: ${props => props.theme.grid.breakpoints[0]}) {
        margin: 1rem 0;
    }
`;

export const TranscriptPanel = styled(Panel)`
    margin: 1rem 0;

    .transcripts-header {
        color: #343a72;
        font-weight: bold;
        &:hover {
            cursor: pointer;
        }
        &:focus-visible {
            ${focusStyles}
            border-radius: 2px;
        }
    }

    .rc-collapse-content {
        overflow: visible;
    }

    .react-tabs {
        display: grid;
        grid-template: auto auto / 1fr auto;

        &__tab-panel {
            grid-column: 1 / span 2;
        }
    }

    .arrow {
        border-style: solid;
        border-width: 0.15em 0.15em 0 0;
        content: '';
        display: block;
        height: 0.5em;
        right: 0.15em;
        position: relative;
        top: 0.5em;
        transform: rotate(-45deg);
        width: 0.5em;
        top: 0.5em;
        transform: rotate(135deg);
        float: right;
    }
    [aria-expanded='true'] .arrow {
        transform: rotate(-45deg);
    }
`;
