import styled from 'styled-components';

const ContentContainer = styled.div`
    width: 100%;
    margin: auto;
    overflow: scroll;
    max-height: 100vh;
    height: 100%;
    @media (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        width: 90%;
    }
`;

const UnderlineSection = styled.div`
    height: 1px;
    width: 100%;
    background-color: rgba(219, 219, 233);
    margin-top: 10px;
`;

export { UnderlineSection, ContentContainer };
