import React from 'react';
import { Translation } from '../../../components/Translation';

export function getWhiteListMessage(urls) {
    return {
        heading: <Translation translationKey="troubleshooter.fail.urls_header" defaultValue="Blocked URLs" />,
        body: (
            <div>
                <Translation
                    translationKey="troubleshooter.fail.urls_body"
                    defaultValue="It looks like some of our URLs are blocked by your firewall, please contact your IT Administrator to allow access to the following URLs:"
                />
                <ul>
                    {urls.map(url => (
                        <li key={url.toString()}>{url}</li>
                    ))}
                </ul>
            </div>
        ),
    };
}

export async function checkUrlPairWithFetch(urlPair) {
    try {
        const response = await fetch(`https://${urlPair[0]}`, {
            // eslint is outdated for this as AbortSignal is now widely supported so having to suppress error
            // eslint-disable-next-line compat/compat
            signal: AbortSignal?.timeout(10000),
            method: 'HEAD',
        });
        return {
            url: urlPair[1],
            result: response.status === 200 || response.status === 201 || response.status === 202,
        };
    } catch {
        return { url: urlPair[1], result: false };
    }
}

export async function checkUrlWithFetchNoCors(url) {
    try {
        await fetch(`https://${url}`, {
            // eslint is outdated for this as AbortSignal is now widely supported so having to suppress error
            // eslint-disable-next-line compat/compat
            signal: AbortSignal?.timeout(10000),
            method: 'HEAD',
            mode: 'no-cors',
        });
        return {
            url,
            result: true,
        };
    } catch {
        return { url, result: false };
    }
}

export function checkUrlWithFrame(url) {
    return new Promise(resolve => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = `https://${url}`;

        iframe.onload = () => {
            document.body.removeChild(iframe);
            resolve({ url, result: true });
        };

        iframe.onerror = () => {
            document.body.removeChild(iframe);
            resolve({ url, result: false });
        };
    });
}

export function checkUrlWithFavicon(url) {
    const u = `https://${url}/favicon.ico?${Date.now()}`;
    return new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve({ url, result: true });
        img.onerror = () => resolve({ url, result: false });
        img.src = u;
    });
}

async function checkAllAssets(assets) {
    const keys = Object.keys(assets);
    const promiseArray = keys.map(key => {
        const { urls, resolverFunc } = assets[key];
        return urls.map(resolverFunc);
    });
    return Promise.all(promiseArray.flat(Infinity));
}

async function WhitelistChecker() {
    const urlsToCheck = {
        apis: {
            resolverFunc: checkUrlPairWithFetch,
            urls: [
                ['fonts.googleapis.com/css?family=Roboto', 'fonts.googleapis.com'],
                ['fonts.gstatic.com/stats/Roboto/normal/400', 'fonts.gstatic.com'],
                ['sentry.io/api/0/', 'sentry.io'],
                ['a.calameoassets.com/5441830/resources/a49d4b5852e2/assets?_u=1709023', '*.calemoassets.com'],
                ['status.pendo.io', 'pendo.io'],
            ],
        },
        favicons: {
            resolverFunc: checkUrlWithFavicon,
            urls: [
                'cloudinary.com',
                'twig-science.herokuapp.com',
                'twigeducation.com',
                'use.typekit.net',
                'calameo.com',
                'readspeaker.com',
                'newrelic.com',
                'hotjar.com',
                'box.com', // Boxcloud no longer exists and can be checked via this
                'singleintegration.com',
                'twigsciencetools.com',
                'binumi.com',
                'docs.google.com',
                'a.twigscience.com',
            ],
        },
        other: {
            resolverFunc: checkUrlWithFetchNoCors,
            urls: [
                'resource.twigscience.com',
                'google-analytics.com',
                'tagmanager.google.com',
                // twigsciencereporter only works with the www. hence the lack of consistency
                'www.twigsciencereporter.com',
                'acsbapp.com',
                'www.imaginelearning.com',
            ],
        },
    };

    const result = await checkAllAssets(urlsToCheck);

    const unreachableURLs = result.filter(r => !r.result).map(r => r.url);
    const hasError = unreachableURLs.length > 0;
    return {
        errorStatus: hasError,
        message: hasError ? getWhiteListMessage(unreachableURLs) : null,
    };
}

export default WhitelistChecker;
