import React from 'react';
import { PropTypes } from 'prop-types';
import { theme } from '@twigeducation/ts-fe-components';
import { RightArrow } from '@twigeducation/ts-fe-components/components/Icons';
import { useTranslation } from '../../../../../hooks/useTranslation';
import {
    ContentKey,
    ContentText,
    ContentType,
    ContentTypeKey,
    ContentValue,
    ContentValuePage,
    FlexChild,
    FlexContainer,
    LeftSection,
    Panel,
    PanelHead,
    PanelMainContainer,
    PrimaryButton,
    RightSection,
    SecondaryButton,
    SecondaryButtonContainer,
    SpanishDynamicContainer,
    StyledSpan,
    ToolButton,
    ToolButtonIcon,
    ToolSection,
} from './AssessmentPanel.styled';

import AssessmentBreadcrumb from './AssessmentBreadcrumb';

const AssessmentPanel = ({ item }) => {
    const { t } = useTranslation();
    const viewBtn = item?.teacherLessonUrl ? item?.teacherLessonUrl : item?.apUrl;

    return (
        <Panel styleType="white">
            <PanelMainContainer>
                <LeftSection>
                    <PanelHead>
                        <AssessmentBreadcrumb item={item} />
                        <div>
                            <ContentTypeKey>{t('assessment_panel.type', 'Type')}:</ContentTypeKey>
                            <ContentType>{item.responseType}</ContentType>
                        </div>
                    </PanelHead>
                    <hr />
                    <FlexContainer>
                        {item.whatStudentsDo && (
                            <FlexChild>
                                <ContentKey>{t('assessment_panel.What_students_do', 'What students do')}:</ContentKey>
                                <br />
                                <ContentText>{item.whatStudentsDo}</ContentText>
                            </FlexChild>
                        )}
                        {(item.assessmentType === 'Key_Language_Proficiency' ||
                            item.assessmentType === 'Formative_Assessment') && (
                            <FlexChild>
                                <ContentKey>
                                    {t('assessment_panel.What_is_being_assessed', "What's being assessed?")}:
                                </ContentKey>
                                <br />
                                <StyledSpan>{item.whatsBeingAssessed}</StyledSpan>
                            </FlexChild>
                        )}
                        {item.assessmentType === 'Pre_Exploration' && (
                            <FlexChild>
                                <ContentKey>
                                    {t('assessment_panel.misconception_identify', 'Misconceptions identified')}:
                                </ContentKey>
                                <br />
                                <StyledSpan>{item.whatsBeingAssessed}</StyledSpan>
                            </FlexChild>
                        )}
                        {(item.assessmentType === 'Benchmark_and_Multiple_Choice' ||
                            item.assessmentType === 'Performance_Task') && (
                            <FlexChild>
                                <ContentKey> {t('assessment_panel.standard', 'Standards')}:</ContentKey>
                                <br />
                                <StyledSpan>{item.whatsBeingAssessed}</StyledSpan>
                            </FlexChild>
                        )}
                        {item.description && (
                            <FlexChild>
                                <ContentKey> {t('assessment_panel.description', 'Description')}: </ContentKey>
                                <br />
                                <ContentValue>{item.description}</ContentValue>
                            </FlexChild>
                        )}
                    </FlexContainer>
                </LeftSection>

                <RightSection>
                    <SpanishDynamicContainer>
                        {viewBtn && (
                            <PrimaryButton
                                language={item.language}
                                primary
                                target="_blank"
                                disabled={!viewBtn}
                                href={viewBtn}
                            >
                                {t('assessment_panel.view', 'View')}
                            </PrimaryButton>
                        )}
                        <SecondaryButtonContainer>
                            <SecondaryButton
                                language={item.language}
                                secondary
                                href={item.teacherLessonPrintLink}
                                disabled={!item?.teacherLessonPrintLink}
                                target="_blank"
                            >
                                {t('assessment_panel.teacher_edition', 'Teacher Edition')}
                            </SecondaryButton>
                            <ContentValuePage>{item.teacherLessonPrintPage}</ContentValuePage>
                        </SecondaryButtonContainer>
                        <SecondaryButtonContainer>
                            {item?.assessmentType !== 'Benchmark_and_Multiple_Choice' && (
                                <SecondaryButton
                                    disabled={!item.studentLessonPrintLink}
                                    secondary
                                    lan={item.language}
                                    href={item.studentLessonPrintLink}
                                    target="_blank"
                                >
                                    {t('assessment_panel.twig_book', 'Twig Book')}
                                </SecondaryButton>
                            )}
                            <ContentValuePage>{item.studentLessonPrintPage}</ContentValuePage>
                        </SecondaryButtonContainer>
                    </SpanishDynamicContainer>
                    {item.assessmentTool && (
                        <ContentKey> {t('assessment_panel.assessment_tool', 'Assessment Tool')}: </ContentKey>
                    )}
                    <ToolSection>
                        <ContentValue>{item.assessmentTool}</ContentValue>
                        {item?.assessmentTool === 'Twig Book' ? (
                            <ToolButton href={item.studentLessonPrintLink} secondary iconOnly target="_blank">
                                <ToolButtonIcon>
                                    <RightArrow size={10} color={theme.secondaryColor} />
                                </ToolButtonIcon>
                            </ToolButton>
                        ) : (
                            item.toolUrl && (
                                <ToolButton href={item.toolUrl} secondary iconOnly target="_blank">
                                    <ToolButtonIcon>
                                        <RightArrow size={10} color={theme.secondaryColor} />
                                    </ToolButtonIcon>
                                </ToolButton>
                            )
                        )}
                    </ToolSection>
                </RightSection>
            </PanelMainContainer>
        </Panel>
    );
};

AssessmentPanel.propTypes = {
    item: PropTypes.shape({
        AssessmentTool: PropTypes.string,
        AssessmentToolURL: PropTypes.string,
        What_students_do: PropTypes.string,
        dq: PropTypes.string,
        id: PropTypes.string,
        lesson: PropTypes.string,
        result: PropTypes.string,
        supplementData: PropTypes.shape({
            misconceptionIdentified: PropTypes.arrayOf(PropTypes.string),
        }),
        teacher_edition: PropTypes.string,
        teacher_editionURL: PropTypes.string,
        twig_book: PropTypes.string,
        twig_bookURL: PropTypes.string,
        type: PropTypes.string,
        viewURL: PropTypes.string,
    }).isRequired,
};

export default AssessmentPanel;
