import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import * as Sentry from '@sentry/react';
import { Button } from '@twigeducation/ts-fe-components';
import { Download } from '@twigeducation/ts-fe-components/components/Icons';
import styled, { css } from 'styled-components';

const StyledDownloadBtn = styled(Button)`
    padding: 0.5rem 1rem;
    right: ${props => props.right || '1rem'};
    top: ${props => props.top || 'auto'};

    ${props =>
        props.position &&
        css`
            position: ${props.position};
        `}

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        right: ${props => props.right || '70px'};
        top: ${props => props.top || '22px'};
    }
`;

class DownloadButton extends React.Component {
    downloadFile(fileUrl, title, extension) {
        const pdfRequest = new XMLHttpRequest();
        pdfRequest.open('GET', fileUrl, true);
        pdfRequest.responseType = 'blob';

        pdfRequest.onload = () => {
            if (pdfRequest.status !== 200) {
                Sentry.captureMessage(`There was an error downloading PDF file - ${title}`);
            } else {
                const file = new Blob([pdfRequest.response], {
                    type: 'binary/octet-stream',
                });
                FileSaver.saveAs(file, `${title}.${extension}`);
            }
        };
        pdfRequest.send();
    }

    render() {
        const {
            fileUrl,
            title,
            buttonText,
            fileExtension,
            className,
            analyticsEventForDownloadButtonClick,
            top,
            right,
            position,
        } = this.props;

        return (
            <StyledDownloadBtn
                className={className}
                iconLeft
                onClick={() => {
                    analyticsEventForDownloadButtonClick(this.downloadFile(fileUrl, title, fileExtension));
                }}
                secondary
                data-at="download-btn"
                top={top}
                right={right}
                position={position}
            >
                <Download size={14} />
                {buttonText}
            </StyledDownloadBtn>
        );
    }
}

DownloadButton.defaultProps = {
    title: 'download',
    fileUrl: null,
    buttonText: 'Download',
    fileExtension: null,
    className: 'download-button',
    analyticsEventForDownloadButtonClick: () => {},
};

DownloadButton.propTypes = {
    fileUrl: PropTypes.string,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    fileExtension: PropTypes.string,
    className: PropTypes.string,
    analyticsEventForDownloadButtonClick: PropTypes.func,
    top: PropTypes.string,
    right: PropTypes.string,
    position: PropTypes.string,
};

export default DownloadButton;
