import React from 'react';
import PropTypes from 'prop-types';
import { PanelContainer, Heading, PanelWrapper, Subtitle } from './Panel.styled';

const Panel = ({ children, title, subTitle, minContainerHeight }) => (
    <PanelContainer data-at="panel-container">
        <Heading data-at="panel-heading">
            {title}
            {subTitle && <Subtitle>{subTitle}</Subtitle>}
        </Heading>

        <PanelWrapper data-at="panel-wrapper" style={{ minHeight: minContainerHeight || 'auto' }}>
            {children}
        </PanelWrapper>
    </PanelContainer>
);

Panel.defaultProps = {
    subTitle: '',
    minContainerHeight: '',
};

Panel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    title: PropTypes.node.isRequired,
    subTitle: PropTypes.node,
    minContainerHeight: PropTypes.string,
};

export default Panel;
